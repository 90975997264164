// extracted by mini-css-extract-plugin
export var contactFormSection = "contactForm-module--contactFormSection--1FUVT";
export var contactForm = "contactForm-module--contactForm--1vdf7";
export var contactFormRow = "contactForm-module--contactFormRow--oGCga";
export var asterisk = "contactForm-module--asterisk--2IyCb";
export var messageBox = "contactForm-module--messageBox--3i-Aa";
export var privacyPolicy = "contactForm-module--privacyPolicy--ekC15";
export var toggleButton = "contactForm-module--toggleButton--1frqJ";
export var addressEntry = "contactForm-module--addressEntry--2Ovve";
export var checkBoxRow = "contactForm-module--checkBoxRow--1iPUq";
export var successMsg = "contactForm-module--successMsg--37TJh";
export var failMsg = "contactForm-module--failMsg--GLBby";