import React, { useState } from 'react'
import Button from 'components/Button'
import Heading from 'components/Heading'
import { useQueryParam, StringParam } from "use-query-params";

import { contactFormSection, contactForm, contactFormRow, 
    messageBox, privacyPolicy, toggleButton, addressEntry, 
    checkBoxRow, asterisk } from './contactForm.module.css'

const EMAIL = 0;
const TELEPHONE = 1;

function ContactForm() {

    const [toggleBoxItem, setToggleBoxItem] = useState(0);
    const [itemLabel, setItemLabel] = useState("Email address");
    const [successQuery] = useQueryParam("success", StringParam);

    function toggleBoxLeft(e) {
        setToggleBoxItem(EMAIL)
        setItemLabel("Email address")
    }

    function toggleBoxRight(e) {
        setToggleBoxItem(TELEPHONE)
        setItemLabel("Phone no.")
    }

    let activeButtonStyle = {
        background: `var(--seasonal-color)`,
        color: `var(--dark-blue)`
    }

    return (
        <section className={contactFormSection}>

            <Heading level='h2'>Contact Us</Heading>

            {successQuery === "1" ? 
                <p>Thanks for your enquiry!</p>
                :  (successQuery === "0") ?
                <p>There was an error submitting your enquiry. Please ensure all required fields (*) are filled correctly.</p>
                : null
            }

            <form action="/contact-form.php" className={contactForm} method="POST">
                <div className={contactFormRow}>
                    <label>
                        <p>First Name<span className={asterisk}>*</span></p>
                        <input type="text" name="fname" required/>
                    </label>
                    <label>
                        <p>Last Name<span className={asterisk}>*</span></p>
                        <input type="text" name="sname" required/>
                    </label>
                </div>
                
                <div className={contactFormRow}>
                    <label>
                        <p>Preferred method of contact</p>
                        <div className={ toggleButton } >
                            <button type="button" onClick={toggleBoxLeft} style={toggleBoxItem === EMAIL ? activeButtonStyle : {}}>
                                <p style={toggleBoxItem === EMAIL ? {fontWeight: `600`} : {}}>Email Address</p>
                            </button>
                            <button type="button" tabIndex={0} onClick={toggleBoxRight} style={toggleBoxItem === TELEPHONE ? activeButtonStyle : {}}>
                                <p style={toggleBoxItem === TELEPHONE ? {fontWeight: `600`} : {}}>Phone no.</p>
                            </button>
                        </div>
                    </label>
                    
                    <label className={addressEntry}>
                        <p>{itemLabel}<span className={asterisk}>*</span></p>
                        <input name="contact" type={ toggleBoxItem === EMAIL ? "email" : "tel" } required/>
                    </label>
                </div>
                
                <label className={[contactFormRow, messageBox].join(' ')}>
                    <p>Message<span className={asterisk}>*</span></p>
                    <textarea name="message" required></textarea>
                </label>

                <div className={[contactFormRow, privacyPolicy].join(' ')}>
                    <label className={checkBoxRow}>
                        <input name="tandc" type="checkbox" required />
                        <p>By checking this box, you confirm that you have read and agree to our Terms of Service, and that you have you read our <span>Privacy Policy</span></p>
                    </label>
                    
                    <Button role="submit">SUBMIT&nbsp;ENQUIRY</Button>
                </div>
                
            </form>

        </section>
    )
}

export default ContactForm