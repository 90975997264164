import * as React from 'react'
import Header from "components/Header"
import Footer from "components/Footer"
import ContactForm from "components/ContactForm"

const GeneralPage = () => {
  return (
    <main>
        <Header />  
        <ContactForm/>
        <Footer />
    </main>
  )
}

export default GeneralPage