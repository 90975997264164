import { Link } from "gatsby";
import React from "react";

import { button, hvrSweepToRight } from "./button.module.css"
import './button.scss'

const validColours = ["white", "dark-blue"];

const sanitize = prop => (prop ? prop.toLowerCase() : "");
const selectValid = (prop, validArr, fallBack) =>
    validArr.includes(prop) ? prop : fallBack;

function Button({ children, textColor, to, ...props }) {
    const SelectedColour = selectValid(sanitize(textColor), validColours, "dark-blue");

    const classes = [button, hvrSweepToRight, `Button__${SelectedColour}`].join(" ")

    // If no url ref then treat as native button element.
    if( to == null ){
        return <button role="button" className={ classes } { ...props }>{ children }</button>
    }
    
    return (
        <Link to={to} {...props}>
            <span className={ classes } { ...props }>{ children }</span>
        </Link>
    )
}

Button.defaultProps ={
    to : null,
    textColor: 'dark-blue',
}

export default Button